// Form.js

import React, { useState, useEffect } from 'react';

const SERVERLESS_FN_URL = "/submit";
const SPECIAL_FIRST_NAME = "Ellen";
const SPECIAL_LAST_NAME = "Ripley";
const TURNSTILE_SITE_KEY = process.env.REACT_APP_TURNSTILE_SITE_KEY; // Ensure this is set appropriately

// Function to dynamically load the Turnstile script
const loadTurnstileScript = () => {
  const existingScript = document.getElementById('turnstile-script');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
    script.id = 'turnstile-script';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    console.log('[Form] Turnstile script loaded');
  } else {
    console.log('[Form] Turnstile script already exists');
  }
};

const Form = () => {
  const [firstName, setFirstName] = useState(''); // Initial state is empty
  const [lastName, setLastName] = useState('');   // Initial state is empty
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isSpecialName, setIsSpecialName] = useState(false);

  // Function to handle Turnstile success callback
  const handleTurnstile = (token) => {
    console.log('[Form] Turnstile token received:', token);
    const turnstileInput = document.getElementById('cf-turnstile-response');
    turnstileInput.value = token;
  };

  // Function to handle Turnstile errors
  const handleTurnstileError = () => {
    console.error('[Form] Turnstile encountered an error');
    // Allow the form submission to proceed
    setErrorMessage('CAPTCHA encountered an error. You can still submit the form.');
  };

  // Function to handle Turnstile expiration
  const handleTurnstileExpired = () => {
    console.warn('[Form] Turnstile token expired');
    const turnstileInput = document.getElementById('cf-turnstile-response');
    turnstileInput.value = '';
    // Reset the Turnstile widget
    if (window.turnstile) {
      window.turnstile.reset('#turnstile-widget');
    }
  };

  // Function to dynamically load/reload the Turnstile widget
  const loadTurnstile = () => {
    const existingTurnstile = document.querySelector('#turnstile-widget');
    if (existingTurnstile) {
      existingTurnstile.remove();
      console.log('[Form] Existing Turnstile widget removed');
    }

    if (!isSpecialName) {
      const turnstileDiv = document.createElement('div');
      turnstileDiv.id = 'turnstile-widget';
      turnstileDiv.className = 'cf-turnstile';
      turnstileDiv.setAttribute('data-sitekey', TURNSTILE_SITE_KEY);
      turnstileDiv.setAttribute('data-callback', 'handleTurnstile');
      turnstileDiv.setAttribute('data-error-callback', 'handleTurnstileError');
      turnstileDiv.setAttribute('data-expired-callback', 'handleTurnstileExpired');
      turnstileDiv.setAttribute('data-retry', 'never');
      turnstileDiv.setAttribute('data-theme', 'light');
      turnstileDiv.setAttribute('data-size', 'normal');

      document.getElementById('turnstile-container').appendChild(turnstileDiv);
      console.log('[Form] Turnstile widget added to the DOM');

      if (window.turnstile) {
        window.turnstile.render('#turnstile-widget');
        console.log('[Form] Turnstile widget rendered');
      } else {
        console.warn('[Form] Turnstile API not loaded yet');
      }
    } else {
      console.log('[Form] Special name detected, skipping Turnstile widget');
    }
  };

  // Effect to load the Turnstile script and widget on mount
  useEffect(() => {
    window.handleTurnstile = handleTurnstile; // Expose the function globally
    window.handleTurnstileError = handleTurnstileError; // Expose the function globally
    window.handleTurnstileExpired = handleTurnstileExpired; // Expose the function globally

    loadTurnstileScript(); // Load the Turnstile API script

    // Wait for the script to load before rendering the widget
    const scriptLoadInterval = setInterval(() => {
      if (window.turnstile || isSpecialName) {
        loadTurnstile(); // Load the widget
        clearInterval(scriptLoadInterval);
      }
    }, 100);

    // Optional: Timeout if Turnstile fails to load
    setTimeout(() => {
      if (!window.turnstile && !isSpecialName) {
        console.error('[Form] Turnstile script failed to load');
        setErrorMessage('CAPTCHA failed to load. You can still submit the form.');
        clearInterval(scriptLoadInterval);
      }
    }, 5000); // Adjust the timeout as needed

    return () => {
      clearInterval(scriptLoadInterval);
    };
  }, [isSpecialName]);

  // Effect to handle name changes and check for the special name
  useEffect(() => {
    if (
      firstName.trim().toLowerCase() === SPECIAL_FIRST_NAME.toLowerCase() &&
      lastName.trim().toLowerCase() === SPECIAL_LAST_NAME.toLowerCase()
    ) {
      setIsSpecialName(true);
      console.log('[Form] Special name entered:', firstName, lastName);
    } else {
      setIsSpecialName(false);
      console.log('[Form] Name changed:', firstName, lastName);
    }
  }, [firstName, lastName]);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const params = new URLSearchParams();
    formData.forEach((value, key) => {
      params.append(key, value);
    });

    try {
      console.log('[Form] Submitting form data:', Object.fromEntries(formData.entries()));

      const response = await fetch(SERVERLESS_FN_URL, {
        method: 'POST',
        body: formData,
        mode: 'same-origin', // Since you're on the same domain
        redirect: 'follow',
      });

      if (response.redirected) {
        console.log('[Form] Redirecting to:', response.url);
        window.location.href = response.url;
      } else {
        const result = await response.text();
        console.log('[Form] Submission result:', result);
        // Optionally handle non-redirect responses
      }
    } catch (error) {
      console.error('[Form] Form submission error:', error);
      window.location.href = `/failure.html?${params.toString()}`;
    }
  };

  return (
    <form
      action={SERVERLESS_FN_URL}
      method="POST"
      className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
      onSubmit={handleSubmit}
    >
      {/* First Name Field */}
      <div className="sm:col-span-2">
        <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
          First Name
        </label>
        <input
          type="text"
          name="first_name"
          id="first_name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          placeholder="Ellen" // Use placeholder
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500"
        />
      </div>

      {/* Last Name Field */}
      <div className="sm:col-span-2">
        <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
          Last Name
        </label>
        <input
          type="text"
          name="last_name"
          id="last_name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          required
          placeholder="Ripley" // Use placeholder
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500"
        />
      </div>

      {/* Message Field */}
      <div className="sm:col-span-2">
        <label htmlFor="message" className="block text-sm font-medium text-gray-700">
          Message
        </label>
        <textarea
          name="message"
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
          rows="4"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500"
        />
      </div>

      {/* Turnstile Widget Container */}
      {!isSpecialName && (
        <div id="turnstile-container" className="sm:col-span-2">
          {/* Turnstile widget will be injected here */}
        </div>
      )}
      <input type="hidden" id="cf-turnstile-response" name="cf-turnstile-response" />

      {errorMessage && (
        <div className="text-red-500 mt-2 sm:col-span-2">
          {errorMessage}
        </div>
      )}
      <div className="sm:col-span-2 sm:flex sm:justify-end">
        <button
          type="submit"
          id="submit-button"
          className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 sm:w-auto"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default Form;
